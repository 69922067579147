<template>
  <v-card
    class="mx-auto"
    width=100%
    height=100%
    flat
    tile
  >
     <v-toolbar
      color="indigo"
      dark
    >
      <v-btn @click="onback"  icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">{{name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
     <v-divider></v-divider>
    <v-card
        class="d-flex flex-row mb-2"
        color="indigo"
        flat
        tile
        height="160px"
      >
      
      <v-card
          color="indigo"
          outlined
          tile
          height="140px"
          width="140px"        
        >
             <v-card
              color="indigo"
              outlined
              tile
              class="pa-8"
            >
    
          <v-img v-if="isCamera" src="../assets/ic_ca.png"></v-img>
          <v-img v-else src="../assets/main_alarm_no.png"></v-img>
         </v-card>
        </v-card>
        
        <v-card
          color="indigo"
          outlined
          tile
          height="140px"
          class="pt-12"
          
        >
          <v-card
              color="indigo"
              outlined
              tile
              class="white--text"
            >
              Name:
            </v-card>
             <v-card
              color="indigo"
              outlined
              tile
              class="white--text"
            >
              Serno:
            </v-card>


        </v-card>
        <v-card
          color="indigo"
          outlined
          tile
          height="140px"
          class="pl-2 pt-12"
        >
          <v-card
              color="indigo"
              outlined
              tile
              class="white--text"
            >
              {{eqname}}
            </v-card>
             <v-card
              color="indigo"
              outlined
              tile
              class="white--text"
            >
              {{serno}}
            </v-card>


        </v-card>

      </v-card>
      
       <v-card
              outlined
              tile
              class="white--text"
            >
       <v-card

              outlined
              tile
              height="40px"
              class="black--text pa-2"
            >
            Base Setting
            </v-card>
              <v-card
              outlined
              tile
              height="40px"
              class="black--text"
            >
           
              <v-btn
              plain
              width="100%"
              block
              >
            <v-icon>mdi-movie-open-edit-outline</v-icon>
            
            <span  class="pl-4">Audio&Video</span>
            <v-spacer></v-spacer>
            <v-icon>mdi-menu-right</v-icon>
           </v-btn>
            </v-card>
            <v-card
              outlined
              tile
              height="40px"
              class="black--text"
            >
           
              <v-btn
              plain
              width="100%"
              block
              >
            <v-icon>mdi-microsoft-internet-explorer</v-icon>
            
            <span  class="pl-4">NetWork</span>
            <v-spacer></v-spacer>
            <v-icon>mdi-menu-right</v-icon>
           </v-btn>
            </v-card>
        </v-card>
 
        <v-bottom-navigation 
         grow   
         absolute
         hide-on-scroll
         horizontal>
            <v-btn  
             plain  
             width="100%"
             @click="deletefun" >
              <span class="red--text">Delete</span>
               <v-icon>mdi-delete</v-icon>             
            </v-btn>  
       </v-bottom-navigation>


  </v-card>
</template>

<script>
import { Base64 } from 'js-base64'
  export default {
  

    data: () => ({
         xsrf:'',
        name: 'Setting',  
        serno:'',
        isCamera:false,
        username: '',
        password: '',
        eqname: '',
        datas: [],
    }),
    created() {
            var xsrf = this.getCookie("_xsrf")
             if(xsrf!=""){
                var xsrflist  = xsrf.split("|");
              
               // console.log("xsrflist ->",xsrflist[0])
                this.xsrf = Base64.decode(xsrflist[0])
                //console.log("xsrf ->",this.xsrf)
             }
     //  console.log("DeviceSetting created   "+this.$route.params.serno);
      var UserInfo = localStorage.getItem('userinfo')
      if(UserInfo!=null && UserInfo!= undefined){
        var obj = JSON.parse(UserInfo);
        this.username = obj.user;
        this.password = obj.pwd;
      }
       this.serno = this.$route.params.serno
       this.eqname = this.$route.params.name
       var eqtype =  this.$route.params.type
       if(eqtype =="CAMERA"){
           this.isCamera = true;

       }else{
           this.isCamera = false;
       }
      //
    },
    mounted(){
          var datas = localStorage.getItem('datas')
         if(datas!=null && datas!= undefined){
           this.datas = JSON.parse(datas);
 
        
           //console.log('beforeCreate datas=>',datas); 
         }
        console.log("DeviceSetting mounted   ");
    },
    destroyed() {
        console.log("DeviceSetting destroyed   ");
    },
    methods: {
      getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
       deletefun:function(){

                let that = this;     
                if(this.serno ==''){
                    this.dialogtitle="Warning";
                    this.dialogmessage="The device number you want to add is empty  !";
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                }
               var sessionon = localStorage.getItem('sessionon')
                var  UserInfo = {user:this.username,pwd:this.password,serno:this.serno};
                
                this.axios.post('/api/delequipment',UserInfo,{
                 headers: {
                  'Access-Control-Allow-Origin': "*",
                  'Authorization': sessionon,
                  'X-Xsrftoken':this.xsrf
                 }
                })
                .then(res=>{
                    
                    
                    if(res.data.state==200){


                      var data  = new Array();
                      that.datas.forEach(item =>{
                        //console.log("Home sortlist   ",item);
                              if(item.serno!=that.serno){
                                    data = data.concat(item);
                              }
                          
                      });
                     localStorage.setItem('datas',JSON.stringify(data));

                      this.global.sendToServer({
                        "eventName": "__unsubscribe",
                        "data": {
                          "sessionId": this.global.getHomeId(),
                          "sessionType": "IE",
                          "messageId": this.global.newGuid(),
                          "from": this.global.getMeId(),
                          "to": that.serno
                          }
                          });
                     that.global.setInitData(false);
                     that.$router.push("/")    
                     }else if(res.data.state==300){
                          this.$router.push('Login') 
                    }else{
                     console.log('res=>',res.data); 
                    this.dialogtitle="Warning";
                    this.dialogmessage=res.data.message+"  error  code "+res.data.state;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
               
                    }
                    
                            
                })
                .catch(error=>{
                       console.log('error=>',error);
                     this.dialogtitle="Warning";
                    this.dialogmessage="net work  error "+error;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                })



       },
       onback: function(){
            
           this.$router.push("/")
      }
  
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
